/* Import Google font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
body {
  background: #E3F2FD;
}
.color-container {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #ddd;
  height: auto;
  overflow: auto;
  border-radius: 10px;
    width: calc(100% - 25px);
}
.color-container .color {
  margin: 2px;
  padding: 2px;
  list-style: none;
  cursor: pointer;
  text-align: center;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 25px rgba(52,87,220,0.08);
  transition: all 0.3s ease;
  border: 1px solid #ddd;
}
.color-container .color:active {
  transform: scale(0.95);
}
.color .rect-box {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  margin: 0 auto;
}
.color:hover .rect-box {
  filter: brightness(107%);
}
.color .hex-value {
  // display: block;
  display: none;
  color: #444;
  user-select: none;
  font-weight: 500;
  font-size: small;
  text-transform: uppercase;
}
.refresh-btn {
  position: fixed;
  color: #fff;
  margin-left: 398px;
  margin-top: -35px;
  cursor: pointer;
  outline: none;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 5px;
  background: #8A6CFF;
  padding: 3px 10px;
  border: 2px solid #fff;
  box-shadow: 0 15px 30px rgb(52 87 220 / 20%);
  transition: all 0.3s ease;
}
.refresh-btn:hover {
  background: #704dff;
}

.color-selected {
  border: 1px solid yellow !important;
}

@media screen and (max-width: 500px) {
  .color-container {
    margin: 10px;
  }
  .color-container .color {
    margin: 8px;
    padding: 5px;
    width: calc(100% / 2 - 20px);
  }
  .color .rect-box {
    width: 100%;
    height: 148px;
  }
  .color .hex-value {
    font-size: 1.05rem;
  }
  .refresh-btn {
    font-size: 1rem;
  }
}
